/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { lrc } from '../../utils/lr-client-instance';
import CurrentEventsController from '../../controllerViews/CurrentEventsController';
import EventMeta from './EventMeta';
import CategoryIcons from '../CategoryIcons';
import Map from '../Map';

import { ShareButton } from '../EventActions/ShareButton';
import { Icon } from '../Icon';
import { PrintButton } from '../EventActions/PrintButton';
import { CalendarButton } from '../EventActions/CalendarButton';
import EventOrganizer from './EventOrganizer';
import { EventRegistration } from './EventRegistration';
import { isTrue } from '../../utils/utils';
import { SignUpButton } from '../EventActions/SignUpButton';
import { DEFAULT_DATE_FORMAT } from '../../constants/Constants';

const EventDetailsDefault = ({ event, config, structureFields, showCurrentEvents, showBackLink }) => {
  const history = useHistory();
  const {
    title, startTime, endTime, description, allDay, attachments,
    uuid, links, subtitle, vocabularies, link_texts, geolocation,
    singleAppointments, small_image_url, AltTag, Bildnachweis, irregularAppointments, imageFocus, organizer
  } = event;

  const startDate = dayjs(startTime);
  const endDate = dayjs(endTime);

  let dateString = `${startDate.format(DEFAULT_DATE_FORMAT)} bis ${endDate.format(DEFAULT_DATE_FORMAT)}`;
  if (allDay) {
    dateString = 'Ganztägig';
  } else if (startDate.isSame(endDate, 'day')) {
    dateString = startDate.format(DEFAULT_DATE_FORMAT);
  } else if (irregularAppointments) {
    dateString = `ab ${startDate.format(DEFAULT_DATE_FORMAT)}`;
  }

  const goBack = () => {
    const { eventCalendarViewPath } = config;
    if (eventCalendarViewPath) {
      window.open(`${window.location.origin}${config.eventCalendarViewPath}`);
    } else {
      history.replace({pathname: "/", search: sessionStorage.getItem("queryParams")});
    }
  };

  return (
    <div className="event-details-container">
      <div className="event-details">
        <div className="data-head">
          {
            showBackLink &&
            <span style={{ display: 'inherit' }}>
              <Icon icon="chevron-left"/>
              <button
                className="xdbtn--link"
                onClick={goBack}
                aria-label="Zum Kalender"
              >
              Zum Kalender
              </button>
            </span>
          }

          <ul className="meta-links icon-small">
            <li>
              <PrintButton/>
            </li>
            <li>
              <ShareButton
                title={title}
                uuid={uuid}
              />
            </li>
            <li>
              <CalendarButton uuid={uuid}/>
            </li>
          </ul>
        </div>

        <div className="data-content">
          <h1 className="xdh1">{title}</h1>

          {
            isTrue(config.showImage) && small_image_url && small_image_url.length > 0 &&
            <div>
              <div className="clearfix"/>
              <div className={'event-details__image'}>
                <img
                  src={lrc.getServerUrl() + small_image_url}
                  alt={AltTag}
                  className={imageFocus}
                />
                <button
                  className="xdbtn--link copyright"
                >
                  {Bildnachweis || organizer}
                </button>
              </div>
            </div>
          }

          {subtitle && subtitle.length > 0 && <span className="xdh2">{subtitle}</span>}

          <div
            className="meta"
          >
            {`${dateString}${singleAppointments ? '' : ', ' + startDate.format('HH:mm') + ' bis ' + endDate.format('HH:mm') + ' Uhr'}`}
          </div>

          <div className="clearfix"/>

          <div className="event-details__shortinfo">
            <CategoryIcons vocabularies={vocabularies}/>
            <EventMeta
              event={event}
              config={config}
              structureFields={structureFields}
            />
            <SignUpButton event={event}/>
          </div>
          <div>
            <EventOrganizer config={config} event={event}/>
            <EventRegistration config={config} event={event}/>
          </div>

          <div className="clearfix"/>

          <div className="event-details__inner-content">
            {
              isTrue(config.description) && description &&
              <div>
                <h2 className="xdh3">Beschreibung</h2>
                <p dangerouslySetInnerHTML={{ __html: description }}/>
              </div>
            }

            {
              isTrue(config.attachments) && attachments && attachments.length > 0 &&
              <div>
                <span className="xdh3">Zum Herunterladen</span>
                <ul className="link-list icon-tiny">
                  {
                    attachments.map(item => {
                        const title = `${item.title} (${Math.round((item.size / 1000000) * 100) / 100} MB)`;
                        return (
                          <li key={`download-${item.title}`}>
                            <a
                              href={`${window.eventManagerServerUrl}${item.download}`}
                              className="download-icon"
                            >
                              <Icon icon="custom-download"/>
                              {title}
                            </a>
                          </li>
                        );
                      }
                    )}
                </ul>
              </div>
            }

            {
              config.links === 'true' && links && links.length > 0 && <div>
                <h2 className="xdh3">Weitere Informationen</h2>
                <ul className="link-list icon-tiny">
                  {
                    links.map((item, i) => (
                        <li className="link-item">
                          <Icon icon="custom-chevron-right"/>
                          <a
                            href={item}
                            target={(config.baseUrl === '' || (!(item.indexOf(config.baseUrl)) === 0)) && '_blank'}
                            className={config.baseUrl && (item.indexOf(config.baseUrl) === 0) && 'internal-icon'}
                          >
                            {(link_texts && link_texts[i]) || item}
                          </a>
                        </li>
                      )
                    )
                  }
                </ul>
              </div>
            }

            <SignUpButton event={event}/>

            {
              geolocation && <Map event={event}/>
            }


          </div>
        </div>
      </div>


      {
        showCurrentEvents &&
        <CurrentEventsController
          isEventDetail={true}
          uuid={uuid}
        />
      }

    </div>
  );
};

export default EventDetailsDefault;
