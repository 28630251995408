/**
 * User: Niko
 * Date: 08.09.2020
 * Time: 10:14
 */

import React from 'react';
import PropTypes from 'prop-types';
import { lrc } from '../../utils/lr-client-instance';
import { CustomLink } from '../CustomLink';

export const ListItemImageDefault = ({ uuid, title, src, alt, copyright, organizer }) => {

  return (
    <div className="event-item__image">
      <CustomLink
        className="event-item__image-link"
        to={`/-/kalender/event/${uuid}`}
        ariaLabel={`Zur Veranstaltung: ${title}`}
        title={`Zur Veranstaltung: ${title}`}
      >
        {
          src &&
          <img
            src={lrc.getServerUrl() + src}
            alt={alt}
          />
        }
        {
          !src &&
          <img
            className="event-item__image__default-image"
            alt=""
          />
        }
      </CustomLink>
      <button
        className="xdbtn--link copyright "
      >
        {copyright || organizer}
      </button>
    </div>
  );
};

ListItemImageDefault.propTypes = {
  title: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  src: PropTypes.string,
  alt: PropTypes.string,
  copyright: PropTypes.string,
  eventCalendarViewPath: PropTypes.string,
};
